.document-panel {
    display: flex;
    flex-direction: column;
    background: var(--document-panel-bg-color);
    color: var(--document-panel-color);
}

.document-panel .header {
    color: var(--document-panel-secondary-color);
}

.document-panel .title {
    color: var(--document-panel-color);
}

.document-panel .header .disabled {
    pointer-events: none;
    opacity: 0.5;
}

.document-panel .body {
    background: var(--document-panel-pdf-bg-color);
}

.document-panel .rpv-default-layout__sidebar-tabs {
    width: 0;
    border: 0;
}

.document-panel .text-field {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 19.3rem;
}

.document-panel .combo-box {
    min-width: 19.5rem;
}

.document-panel .property-grid-container {
    min-height: 14.5rem;
    max-height: 14.5rem;
    transition: all 300ms ease-out;
    align-content: start;
}

.document-panel .property-grid-container.expanded {
    min-height: 33rem;
    max-height: 33rem;
    transition: all 300ms ease-out;
}

.document-panel .title-grid {
    display: grid;
    grid-template-rows: repeat(2, 3.5rem);
    grid-template-columns: 11rem 1fr;
    grid-auto-flow: column;
    grid-gap: 0 3rem;
}

.document-panel .property-grid {
    display: grid;
    grid-template-rows: repeat(auto-fit, 3.5rem);
    grid-template-columns: repeat(auto-fit, minmax(32rem, 1fr));
    grid-auto-rows: 1fr;
    grid-gap: 0 3rem;
}

.document-panel .property-grid .label {
    min-width: 11rem;
}

.document-panel .info-grid {
    display: grid;
    grid-template-rows: repeat(5, 3.5rem);
    grid-template-columns: 12rem minmax(15rem, 1fr);
    grid-auto-flow: column;
    grid-gap: 0 3rem;
}

.document-panel .sme-grid {
    display: grid;
    grid-template-rows: repeat(auto-fit, 3.5rem);
    grid-template-columns: repeat(auto-fit, minmax(31rem, 1fr));
    grid-auto-rows: 1fr;
    grid-gap: 0 3rem;
}

.document-panel .sme-grid .label {
    min-width: 12rem;
}

.document-panel .card .card-body.expanded {
    min-height: 25rem;
}

.document-panel .info-button {
    background: transparent;
    color: var(--search-results-list-info-color);
    fill: var(--search-results-list-info-color);
}

.document-panel .card.selected .info-button {
    color: var(--search-results-list-selected-info-color);
    fill: var(--search-results-list-selected-info-color);
}

.document-panel .pdf-viewer *,
.document-panel .pdf-viewer *:before,
.document-panel .pdf-viewer *:after{
    -webkit-user-select: text !important;
    -moz-user-select: text !important;
    -ms-user-select: text !important;
    user-select: text !important;
    background: transparent;
}

.document-panel .pdf-viewer .pdf-icon {
    fill: var(--document-panel-pdf-icon-color);
}

.document-panel .pdf-viewer .pagination {
    fill: var(--document-panel-pdf-icon-secondary-color);
    color: var(--document-panel-pdf-color);
    font-size: 1.4rem;
}

.document-panel .tag-button,
.tags-portal .tag-button {
    background: var(--document-panel-button-bg-color);
    height: 1.7rem;
    min-width: 1.7rem !important;
    padding: 0 !important;
}

.document-panel .button {
    min-width: 8.7rem;
}

.document-panel .custom-v-gap > *:not(:first-child):not(.exclude-item) {
    margin-top: 1.7rem;
}

.tags-portal {
    margin-top: -2.55rem;
    width: 50rem;
}

.tags-portal .d-inline-flex {
    gap: 1rem 0;
}

.tags-portal .close {
    top: -1.75rem;
    right: 1rem;
}

.document-panel .ellipsis-button {
    min-width: 3.2rem;
    height: 2rem;
}

.document-panel .ellipsis-button.invisible {
    background: transparent;
}

.document-panel .ellipsis-button.invisible svg {
    fill: transparent;
}

.document-panel .text-right {
    max-width: 11rem;
}

.document-panel .nlp-loader {
    background: transparent;
    width: auto !important;
    justify-content: start !important;
}
