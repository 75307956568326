.text-edit {
    min-height: 3.2rem;
    min-width: 20rem;
}

.text-edit input[type=date],
.text-edit input[type=text] {
    cursor: text;
    border: none;
    border-bottom: 1px solid var(--text-field-border-color);
    background: var(--text-field-bg-color);
    color: var(--text-field-color);
    font-size: 1.5rem;
}

.text-edit input[type=date]::placeholder,
.text-edit input[type=text]::placeholder {
    color: var(--text-field-placeholder-color);
    font-size: 1.5rem;
    font-style: italic;
    font-weight: 300 !important;
}

.text-edit input[type=date]:focus,
.text-edit.edit input[type=date]:focus,
.text-edit input[type=text]:focus,
.text-edit.edit input[type=text]:focus {
    border: none;
    border-bottom: 1px solid var(--text-field-selected-border-color);
    background: var(--text-field-selected-bg-color);
    color: var(--text-field-selected-color);
}

.text-edit.edit input[type=date],
.text-edit.edit input[type=text] {
    border: none;
    border-bottom: 1px solid var(--text-field-edit-border-color);
    background: var(--text-field-edit-bg-color);
    color: var(--text-field-edit-color);
    font-style: italic;
    font-weight: 300 !important;
}

.text-edit.dirty input[type=date],
.text-edit.edit.dirty input[type=date],
.text-edit.dirty input[type=text],
.text-edit.edit.dirty input[type=text] {
    border: none;
    border-bottom: 1px solid var(--text-field-dirty-border-color);
    background: var(--text-field-dirty-bg-color);
    color: var(--text-field-dirty-color);
    font-style: normal;
    font-weight: 600 !important;
}

.text-edit.disabled input[type=date],
.text-edit.disabled input[type=text] {
    background: var(--text-field-disabled-bg-color);
    border-bottom: 1px solid var(--text-field-disabled-border-color);
    color: var(--text-field-disabled-color);
    font-style: normal;
    font-weight: 300 !important;
}

.text-edit input[type=date]::-webkit-calendar-picker-indicator {
    filter: invert(87%) sepia(19%) saturate(0%) hue-rotate(209deg) brightness(91%) contrast(99%);
}

.text-input {
    border: none;
    border-bottom: 1px solid var(--text-field-color);
    background: var(--text-field-bg-color);
    color: var(--text-field-border-color);
}

.text-input::placeholder {
    color: var(--text-field-placeholder-color);
}

.text-input:focus {
    color: var(--text-field-selected-color);
    border-color: var(--text-field-selected-border-color);
    background: var(--text-field-selected-bg-color);
}

.text-area {
    font-family: var(--primary-font-family);
    padding: 1rem;
    border: 0;
    background: var(--text-field-selected-bg-color);
    font-size: 1.6rem;
    color: var(--text-field-color);
    min-width: 80rem;
    min-height: 21rem;
}

.text-area:focus {
    color: var(--text-field-selected-color);
}

.text-area::placeholder {
    font-weight: 300;
    font-style: italic;
    color: var(--text-field-placeholder-color);
}

.text-area.disabled {
    color: var(--text-field-disabled-color);
    font-style: normal;
    font-weight: 300 !important;
}
