body > * {
    --primary-font-family: 'Open Sans', sans-serif;
    --mono-font-family: 'Roboto', sans-serif;

    --app-black: #000000;
    --app-white: #FFFFFF;
    --app-bleach: #F0F0F0;
    --app-whiteSmoke: #EAEAEA;
    --app-silver: #BABABA;
    --app-suvaGrey: #959595;
    --app-shuttleGrey: #5D636B;
    --app-vulcan: #3B3D44;
    --app-bunker: #2B2D32;
    --app-blackRussian: #212227;
    --app-nero: #171717;
    --app-summerSky: #4097E6;
    --app-dodgerBlue: #48A8ff;
    --app-airForceBlue: #6085A6;
    --app-rockBlue: #87A1B9;
    --app-valencia: #D15353;
    --app-puce: #CC8E8E;
    --app-pastelGreen: #73D98B;

    /** Common Colors **/
    --primary-color: var(--app-white);
    --secondary-color: var(--app-black);
    --warning-color: var(--app-puce);
    --caution-color: #ffff00;
    --advisory-color: var(--app-summerSky);
    --accent-color: var(--app-silver);
    --info-color: var(--app-suvaGrey);
    --nominal-color: var(--app-pastelGreen);
    --muted-color: var(--app-shuttleGrey);
    --selected-color: var(--app-dodgerBlue);
    --disabled-color: var(--app-suvaGrey);
    --notification-color: var(--app-valencia);

    --app-background: var(--app-whiteSmoke);

    /* Login Panel */

    --login-panel-background: var(--app-vulcan);
    --login-panel-popup-background: var(--app-vulcan);
    --login-panel-light-popup-background: var(--app-bleach);
    --login-panel-color: var(--app-bleach);
    --login-panel-accent-color: var(--app-airForceBlue);
    --login-panel-info-color: var(--app-whiteSmoke);
    --login-panel-light-info-color: var(--app-suvaGrey);
    --login-panel-dark-bg-color: var(--app-bunker);
    --login-panel-popup-border-color: var(--app-dodgerBlue);

    /* Logout Panel */



    /* System Banner */
    --system-banner-background: var(--app-nero);
    --system-banner-color: var(--app-whiteSmoke);
    --system-banner-accent-color: var(--app-dodgerBlue);
    --system-banner-secondary-color: var(--app-bleach);

    /* Search Results Panel*/
    --search-results-panel-bg-color: transparent;

    --search-results-card-color: var(--app-shuttleGrey);
    --search-results-card-accent-color: var(--app-bunker);/*title*/
    --search-results-card-bg-color: var(--app-bleach);
    --search-results-card-border-color: var(--app-airForceBlue);
    --search-results-card-hover-color: var(--app-shuttleGrey);
    --search-results-card-hover-accent-color: var(--app-bunker);
    --search-results-card-hover-bg-color: var(--app-white);
    --search-results-card-hover-border-color: var(--app-summerSky);
    --search-results-card-selected-color: var(--app-black);
    --search-results-card-selected-accent-color: var(--app-black);
    --search-results-card-selected-bg-color: var(--app-white);
    --search-results-card-selected-border-color: var(--app-dodgerBlue);
    --search-results-card-disabled-color: var(--app-suvaGrey);
    --search-results-card-disabled-accent-color: var(--app-suvaGrey);
    --search-results-card-disabled-bg-color: var(--app-silver);
    --search-results-card-disabled-border-color: var(--app-suvaGrey);
    --search-results-card-deleted-color: var(--app-suvaGrey);
    --search-results-card-deleted-accent-color: var(--app-suvaGrey);
    --search-results-card-deleted-bg-color: var(--app-silver);
    --search-results-card-deleted-border-color: var(--app-suvaGrey);

    --search-results-table-header-color: var(--app-whiteSmoke);
    --search-results-table-header-bg-color: var(--app-shuttleGrey);
    --search-results-table-color: var(--app-shuttleGrey);
    --search-results-table-bg-color: transparent;
    --search-results-table-hover-color: var(--app-shuttleGrey);
    --search-results-table-hover-bg-color: var(--app-white);
    --search-results-table-selected-color: var(--app-black);
    --search-results-table-selected-bg-color: var(--app-white);

    --search-results-list-bg-color: transparent;
    --search-results-list-color: var(--app-suvaGrey);
    --search-results-list-accent-color: var(--app-shuttleGrey);
    --search-results-list-info-color: var(--app-airForceBlue);
    --search-results-list-selected-info-color: var(--app-dodgerBlue);
    --search-results-list-info-accent-color: var(--app-suvaGrey);
    --search-results-list-hover-bg-color: var(--app-white);
    --search-results-list-hover-color: var(--app-shuttleGrey);
    --search-results-list-hover-accent-color: var(--app-bunker);
    --search-results-list-selected-bg-color: var(--app-white);
    --search-results-list-selected-color: var(--app-black);
    --search-results-list-selected-accent-color: var(--app-black);

    /* Document Panel */
    --document-panel-bg-color: var(--app-blackRussian);
    --document-panel-pdf-bg-color: var(--app-blackRussian);
    --document-panel-color: var(--app-bleach);
    --document-panel-secondary-color: var(--app-whiteSmoke);
    --dcoument-panel-accent-color: var(--app-suvaGrey);
    --document-panel-button-bg-color: transparent;
    --document-panel-button-border-color: var(--primary-color);
    --document-panel-pdf-icon-color: var(--app-shuttleGrey);
    --document-panel-pdf-color: var(--app-suvaGrey);
    --document-panel-pdf-icon-secondary-color: var(--app-bleach);

    /* System Toolbar */
    --system-toolbar-background: var(--app-blackRussian);

    /* System Tools Panel */
    --system-tool-panel-background: var(--app-bunker);
    --system-tool-header-background: var(--app-black);
    --system-tool-header-color: var(--app-whiteSmoke);
    --system-tool-color: var(--app-bleach);

    /* Tags Panel */
    --tags-panel-header-background: var(--app-shuttleGrey);
    --tags-panel-header-color: var(--app-whiteSmoke);
    --tags-panel-tag-color: var(--app-silver);

    /* User Management */
    --profile-panel-color: var(--app-whiteSmoke);
    --profile-panel-secondary-color: var(--app-whiteSmoke);
    --profile-panel-accent-color: var(--app-suvaGrey);
    --profile-panel-dirty-color: var(--app-white);

    --profile-info-bg-color: var(--app-shuttleGrey);
    --profile-info-color: var(--app-bleach);
    --profile-info-border-color: var(--app-airForceBlue);
    --profile-info-dirty-color: var(--app-bleach);
    --profile-info-dirty-secondary-color: var(--app-white);
    --profile-info-dirty-border-color: var(--app-dodgerBlue);

    --profile-panel-request-color: var(--app-whiteSmoke);
    --profile-panel-request-bg-color: var(--app-blackRussian);
    --profile-panel-request-border-color: var(--app-dodgerBlue);
    --profile-panel-request-secondary-color: var(--app-silver);
    --profile-panel-request-accent-color: var(--app-suvaGrey);

    --user-request-bg-color: var(--app-blackRussian);
    --user-request-color: var(--app-whiteSmoke);
    --user-request-border-color: var(--app-dodgerBlue);
    --user-request-secondary-color: var(--app-whiteSmoke);

    /* Upload Panel */
    --pending-item-bg-color: var(--app-bleach);
    --pending-item-color: var(--app-bunker);
    --pending-item-border-color: var(--app-airForceBlue);
    --pending-item-hover-bg-color: var(--app-white);
    --pending-item-hover-color: var(--app-bunker);
    --pending-item-hover-border-color: var(--app-summerSky);
    --pending-item-loading-bg-color: var(--app-bleach);
    --pending-item-loading-color: var(--app-silver);
    --pending-item-loading-border-color: var(--app-airForceBlue);
    --pending-item-selected-bg-color: var(--app-white);
    --pending-item-selected-bg-secondary-color: var(--app-bleach);
    --pending-item-selected-color: var(--app-black);
    --pending-item-selected-border-color: var(--app-dodgerBlue);
    --pending-item-disabled-bg-color: var(--app-silver);
    --pending-item-disabled-color: var(--app-suvaGrey);
    --pending-item-disabled-border-color: var(--app-suvaGrey);
    --pending-item-deleted-bg-color: var(--app-silver);
    --pending-item-deleted-color: var(--app-bleach);
    --pending-item-deleted-border-color: transparent;
    --pending-item-accept-button-color: var(--app-dodgerBlue);
    --pending-item-reject-button-color: var(--app-airForceBlue);
    --pending-item-button-bg-color: transparent;
    --pending-item-loading-button-color: var(--app-airForceBlue);
    --pending-item-button-hover-bg-color: var(--app-black);

    /* Widgets */

    /* Buttons */
    --button-color: var(--app-silver);
    --button-bg-color: var(--app-vulcan);
    --button-hover-color: var(--app-white);
    --button-hover-bg-color: var(--app-black);
    /*--button-armed-color: ;*/
    /*--button-armed-bg-color: ;*/
    --button-disabled-color: rgba(186, 186, 186, 0.4);/*--app-silver*/
    --button-disabled-bg-color: var(--app-bunker);
    --button-highlight-color: var(--app-bleach);
    --button-highlight-bg-color: var(--app-shuttleGrey);

    /*--light-button-color: var(--app-white);*/
    /*--light-button-bg-color: var(--app-summerSky);*/
    --light-button-color: var(--app-summerSky);
    --light-button-bg-color: var(--app-white);
    --light-button-hover-color: var(--app-white);
    --light-button-hover-bg-color: var(--app-dodgerBlue);
    --light-button-highlight-color: var(--app-vulcan);
    --light-button-highlight-bg-color: transparent;
    /*--button-armed-color: ;*/
    /*--button-armed-bg-color: ;*/
    /*--button-disabled-color: ;*/
    /*--button-disabled-bg-color: ;*/

    /* Combo Box */
    --combo-box-color: var(--app-silver);
    --combo-box-bg-color: var(--app-vulcan);
    --combo-box-arrow-color: var(--app-airForceBlue);
    --combo-box-hover-color: var(--app-whiteSmoke);
    --combo-box-arrow-hover-color: var(--app-whiteSmoke);
    --combo-box-hover-bg-color: var(--app-black);
    --combo-box-selected-color: var(--app-white);
    --combo-box-selected-bg-color: var(--app-shuttleGrey);
    --combo-box-arrow-selected-bg-color: var(--app-dodgerBlue);
    /*--combo-box-armed-color: ;*/
    /*--combo-box-armed-bg-color: ;*/
    --combo-box-disabled-color: rgba(149, 149, 149, 0.4);/*--app-suvaGrey*/
    --combo-box-disabled-bg-color: var(--app-vulcan);
    --combo-box-disabled-arrow-color: rgba(64, 151, 230, 0.4);/*--app-summerSky*/
    --combo-dox-dirty-color: var(--app-bleach);
    --combo-box-dirty-bg-color: var(--app-vulcan);
    --combo-box-arrow-dirty-color: var(--app-summerSky);
    --combo-box-list-color: var(--app-suvaGrey);
    --combo-box-list-bg-color: var(--app-blackRussian);
    --combo-box-list-hover-color: var(--app-whiteSmoke);
    --combo-box-list-hover-bg-color: var(--app-bunker);
    /*--combo-box-list-selected-color: ;*/
    /*--combo-box-list-selected-bg-color: ;*/

    --light-combo-box-color: var(--app-shuttleGrey);
    --light-combo-box-bg-color: var(--app-bleach);
    --light-combo-box-arrow-color: var(--app-shuttleGrey);
    --light-combo-box-hover-color: var(--app-black);
    --light-combo-box-arrow-hover-color: var(--app-black);
    --light-combo-box-hover-bg-color: var(--app-silver);
    --light-combo-box-selected-color: var(--app-airForceBlue);
    --light-combo-box-selected-bg-color: var(--app-white);
    --light-combo-box-arrow-selected-bg-color: var(--app-airForceBlue);
    /*--light-combo-box-armed-color: ;*/
    /*--light-combo-box-armed-bg-color: ;*/
    /*--light-combo-box-disabled-color: ;*/
    /*--light-combo-box-disabled-bg-color: ;*/
    /*--light-combo-dox-dirty-color: ;*/
    /*--light-combo-box-dirty-bg-color: ;*/
    /*--light-combo-box-arrow-dirty-color: ;*/
    --light-combo-box-list-color: var(--app-suvaGrey);
    --light-combo-box-list-bg-color: var(--app-vulcan);
    /*--light-combo-box-list-hover-color: ;*/
    /*--light-combo-box-list-hover-bg-color: ;*/
    --light-combo-box-list-selected-color: var(--app-dodgerBlue);
    /*--light-combo-box-list-selected-bg-color: ;*/

    /* Card */
    --card-bg-color: transparent;

    /* Check Box */
    --check-box-border-color: var(--app-silver);
    --check-box-hover-border-color: var(--app-summerSky);
    --check-box-selected-border-color: var(--app-airForceBlue);
    --check-box-checkmark-color: var(--app-summerSky);
    --check-box-disabled-border-color: var(--app-suvaGrey);
    --check-box-light-border-color: var(--primary-color);
    --check-box-light-checkmark-color: var(--primary-color);

    /* Global Switch Button */
    --global-switch-button-bg-color: var(--app-vulcan);
    --global-switch-button-icon-color: var(--app-shuttleGrey);
    --global-switch-button-selected-bg-color: var(--app-shuttleGrey);
    --global-switch-button-selected-icon-color: var(--app-silver);

    --light-global-switch-button-bg-color: var(--app-whiteSmoke);
    --light-global-switch-button-icon-color: var(--app-silver);
    --light-global-switch-button-selected-bg-color: var(--app-dodgerBlue);
    --light-global-switch-button-selected-icon-color: var(--app-white);

    /* File Input*/
    --file-input-bg-color: #24262B;
    --file-input-border-color: var(--app-shuttleGrey);
    --file-input-color: var(--app-suvaGrey);
    --file-input-link-color: var(--app-airForceBlue);
    --file-input-icon-color: var(--app-silver);
    --file-input-hover-bg-color: var(--app-blackRussian);
    --file-input-hover-border-color: var(--app-silver);
    --file-input-hover-color: var(--app-suvaGrey);
    --file-input-hover-link-color: var(--app-white);
    --file-input-hover-icon-color: var(--app-white);

    /* Loading Indicator */
    --loading-indicator-background-color: var(--app-white);
    --loading-indicator-border-color: var(--app-airForceBlue);

    /* Progress Bar*/
    --progress-bar-track-background: var(--app-airForceBlue);
    --progress-bar-charged-background: var(--app-dodgerBlue);

    /* Popup */
    --popup-color: var(--app-vulcan);
    --popup-bg-color: var(--app-silver);

    /* Scrollbar */
    --scrollbar-thumb-color: var(--app-shuttleGrey);
    --scrollbar-track-color: var(--app-silver);

    /* Search Box */
    --search-box-color: var(--app-whiteSmoke);
    --search-box-placeholder-color: var(--app-suvaGrey);
    --search-box-bg-color: transparent;
    --search-box-border-color: var(--app-suvaGrey);
    --search-box-selected-color: var(--app-whiteSmoke);
    --search-box-selected-bg-color: transparent;
    --light-search-box-color: var(--app-shuttleGrey);
    --light-search-box-bg-color: var(--app-bleach);
    --light-search-box-selected-color: var(--app-airForceBlue);
    --light-search-box-selected-bg-color: var(--app-white);

    /* Stats */
    /* amcharts */
    --stats-background: var(--app-bleach);
    --stats-color: var(--app-silver);
    --xy-chart-header-background: #8DC77B;
    --xy-chart-header-color: var(--app-bleach);
    --word-cloud-header-background: #E8BA54;
    --word-cloud-header-color: var(--app-bleach);
    --variable-radius-pie-chart-header-background: var(--app-dodgerBlue);
    --variable-radius-pie-chart-header-color: var(--app-bleach);
    --draggable-pie-chart-header-background: #EC1889;
    --draggable-pie-chart-header-color: var(--app-bleach);
    --donut-pie-chart-header-background: #7654E8;
    --donut-pie-chart-header-color: var(--app-bleach);

    /* Switch Button */
    --switch-button-track-color: var(--app-shuttleGrey);
    --switch-button-thumb-color: var(--app-airForceBlue);

    /* Tag */
    --tag-bg-color: var(--app-summerSky);
    --tag-color: var(--app-white);
    --tag-border-color: var(--app-silver);
    --tag-edit-color: var(--app-suvaGrey);
    --tag-edit-bg-color: transparent;
    --tag-edit-border-color: var(--app-suvaGrey);

    /* Text Edit */
    --text-field-color: var(--app-silver);
    --text-field-bg-color: transparent;
    --text-field-border-color: transparent;
    --text-field-placeholder-color: var(--app-suvaGrey);
    /*--text-field-hover-color: ;*/
    /*--text-field-hover-bg-color: ;*/
    /*--text-field-hover-border-color: ;*/
    --text-field-selected-color: var(--app-whiteSmoke);
    --text-field-selected-bg-color: var(--app-vulcan);
    --text-field-selected-border-color: var(--app-silver);
    --text-field-disabled-color: var(--app-suvaGrey);
    --text-field-disabled-bg-color: transparent;
    --text-field-disabled-border-color: transparent;
    --text-field-dirty-color: var(--app-suvaGrey);
    --text-field-dirty-bg-color: transparent;
    --text-field-dirty-border-color: var(--app-silver);
    --text-field-edit-color: var(--app-suvaGrey);
    --text-field-edit-bg-color: transparent;
    --text-field-edit-border-color: var(--app-silver);

    /* Toggle Button */
    --toggle-button-color: var(--app-shuttleGrey);
    --toggle-button-bg-color: transparent;
    --toggle-button-hover-color: var(--app-white);
    --toggle-button-hover-bg-color: var(--app-black);
    --toggle-button-selected-color: var(--app-bleach);
    --toggle-button-selected-bg-color: var(--app-bunker);
    /*--toggle-button-armed-color: ;*/
    /*--toggle-button-armed-bg-color: ;*/
    /*--toggle-button-disabled-color: ;*/
    /*--toggle-button-disabled-bg-color: ;*/

    --light-toggle-button-color: var(--app-shuttleGrey);
    --light-toggle-button-bg-color: transparent;
    --light-toggle-button-hover-color: var(--app-black);
    --light-toggle-button-hover-bg-color: var(--app-silver);
    --light-toggle-button-selected-color: var(--app-airForceBlue);
    --light-toggle-button-selected-bg-color: var(--app-white);
    /*--light-toggle-button-armed-color: ;*/
    /*--light-toggle-button-armed-bg-color: ;*/
    /*--light-toggle-button-disabled-color: ;*/
    /*--light-toggle-button-disabled-bg-color: ;*/

    /* Tooltip */
    --tooltip-color: var(--app-whiteSmoke);
    --light-tooltip-color: var(--app-bunker);
    --tooltip-bg-color: var(--app-vulcan);
    --light-tooltip-bg-color: var(--app-white);

    /*NOT USED*/
    /* Advanced Search */
    --advanced-search-combo-box-color: #959595;
    --advanced-search-combo-box-hover-color: #000000;
    --advanced-search-combo-box-background: #F0F0F0;
    --advanced-search-combo-box-selected-background: #BABABA;
    --advanced-search-combo-box-item-background: #DFDFDF;
    --advanced-search-combo-box-item-selected-background: #BABABA;
    --advanced-search-combo-box-item-hover-background: #BABABA;
    --advanced-search-combo-box-svg-background: #6085A6;
    --advanced-search-combo-box-svg-hover-background: #000000;
    --advanced-search-svg-background: #6085A6;
    --advanced-search-svg-hover-background: #4097E6;
    --advanced-search-color: #6085A6;
    --advanced-search-border-color: #6085A6;
    --advanced-search-odd-row-background: rgba(186, 186, 186, 0.5);
}
