.tektonux-logo {
    background-image: url("../images/tektonux_logo.png");
    background-repeat: no-repeat;
    background-color: transparent;
    background-position: center;
    background-size: contain;
    width: 11.9rem;
    height: 2.9rem;
}

.nano-image-container {
    height: 1.4rem;
    width: 1.4rem;
}

.tiny-image-container {
    height: 1.8rem;
    width: 1.8rem;
}

.small-image-container {
    height: 2.4rem;
    width: 2.4rem;
    min-height: 2.4rem;
    min-width: 2.4rem;
}

.medium-image-container {
    height: 3.2rem;
    width: 3.2rem;
    min-height: 3.2rem;
    min-width: 3.2rem;
}

.large-image-container {
    height: 4.0rem;
    width: 4.0rem;
    min-height: 4.0rem;
    min-width: 4.0rem;
}

.xlarge-image-container {
    height: 4.8rem;
    width: 4.8rem;
    min-height: 4.8rem;
    min-width: 4.8rem;
}
