.system-banner {
    background: var(--system-banner-background);
    width: 100vw;
}

.system-banner .title {
    color: var(--system-banner-color);
}

.system-banner .text {
    color: var(--system-banner-secondary-color);
}

.system-banner .system-banner-text-accent {
    color: var(--system-banner-accent-color);
}

.system-banner .button {
    min-width: 8.7rem;
}

@media screen and (max-width: 800px) {
    .system-banner .hidden-first {
        display: none;
    }
}

@media screen and (max-width: 600px) {
    .system-banner .hidden-second {
        display: none;
    }
}
