@import 'reset.css';
@import 'animations.css';
@import 'font-awesome.min.css';
@import 'icofont.css';
@import 'images.css';
@import 'shapes.css';
@import 'text.css';
@import 'utilities.css';

html,
body {
    height: 100%;
    width: 100%;
    position:relative;
    overflow: hidden;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.view-container > * {
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.view-container > .modal {
    position: inherit;
    overflow: inherit;
    width: inherit;
    height: inherit;
}
