.align-baseline {
    vertical-align: baseline !important;
}

.align-top {
    vertical-align: top !important;
}

.align-middle {
    vertical-align: middle !important;
}

.align-bottom {
    vertical-align: bottom !important;
}

.align-text-bottom {
    vertical-align: text-bottom !important;
}

.align-text-top {
    vertical-align: text-top !important;
}

.float-left {
    float: left !important;
}

.float-right {
    float: right !important;
}

.float-none {
    float: none !important;
}

.overflow-auto {
    overflow: auto !important;
}

.overflow-hidden {
    overflow: hidden !important;
}

.overflow-scroll {
    overflow: scroll !important;
}

.d-none {
    display: none !important;
}

.d-inline {
    display: inline !important;
}

.d-inline-block {
    display: inline-block !important;
}

.d-block {
    display: block !important;
}

.d-table {
    display: table !important;
}

.d-table-row {
    display: table-row !important;
}

.d-table-cell {
    display: table-cell !important;
}

.d-flex {
    display: flex !important;
}

.d-inline-flex {
    display: inline-flex !important;
}

.d-grid {
    display: grid;
    grid-column-gap: 1.0rem;
    grid-row-gap: 1.0rem;
}

.shadow {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
    box-shadow: none !important;
}

.position-static {
    position: static !important;
}

.position-relative {
    position: relative !important;
}

.position-absolute {
    position: absolute !important;
}

.position-fixed {
    position: fixed !important;
}

.position-sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
}

.border {
    border: 0.1rem solid var(--primary-color) !important;
}

.border.lg {
    border-width: 0.5rem !important;
}

.border-0 {
    border: 0 !important;
}

.border-top {
    border-top: 0.1rem solid var(--primary-color) !important;
}

.border-top-0 {
    border-top: 0 !important;
}

.border-right {
    border-right: 0.1rem solid var(--primary-color) !important;
}

.border-right-0 {
    border-right: 0 !important;
}

.border-bottom {
    border-bottom: 0.1rem solid var(--primary-color) !important;
}

.border-bottom-0 {
    border-bottom: 0 !important;
}

.border-left {
    border-left: 0.1rem solid var(--primary-color) !important;
}

.border-left-0 {
    border-left: 0 !important;
}

.border-primary {
    border-color: var(--primary-color) !important;
}

.border-secondary {
    border-color: var(--secondary-color) !important;
}

.border-warning {
    border-color: var(--warning-color) !important;
}

.border-caution {
    border-color: var(--caution-color) !important;
}

.border-advisory {
    border-color: var(--advisory-color) !important;
}

.border-accent {
    border-color: var(--accent-color) !important;
}

.border-info {
    border-color: var(--info-color) !important;
}
border-dark
.border-nominal {
    border-color: var(--nominal-color) !important;
}

.border-muted {
    border-color: var(--muted-color) !important;
}

.border-selected {
    border-color: var(--selected-color) !important;
}

.border-disabled {
    border-color: var(--disabled-color) !important;
}

.border-notification {
    border-color: var(--notification-color) !important;
}

.border-dashed {
    border-style: dashed !important;
}

.w-25 {
    width: 25% !important;
}

.w-33 {
    width: 33% !important;
}

.w-50 {
    width: 50% !important;
}

.w-75 {
    width: 75% !important;
}

.w-100 {
    width: 100% !important;
}

.w-auto {
    width: auto !important;
}

.mw-100 {
    max-width: 100% !important;
}

.vw-100 {
    width: 100vw !important;
}

.min-vw-100 {
    min-width: 100vw !important;
}

.h-25 {
    height: 25% !important;
}

.h-50 {
    height: 50% !important;
}

.h-75 {
    height: 75% !important;
}

.h-100 {
    height: 100% !important;
}

.h-auto {
    height: auto !important;
}

.mh-100 {
    max-height: 100% !important;
}

.vh-100 {
    height: 100vh !important;
}

.min-vh-100 {
    min-height: 100vh !important;
}

.flex-fill {
    flex: 1 1 auto !important;
}

.flex-row {
    flex-direction: row !important;
}

.flex-column {
    flex-direction: column !important;
}

.flex-row-reverse {
    flex-direction: row-reverse !important;
}

.flex-column-reverse {
    flex-direction: column-reverse !important;
}

.flex-grow-0 {
    flex-grow: 0 !important;
}

.flex-grow-1 {
    flex-grow: 1 !important;
}

.flex-shrink-0 {
    flex-shrink: 0 !important;
}

.flex-shrink-1 {
    flex-shrink: 1 !important;
}

.flex-basis-0 {
    flex-basis: 0 !important;
}

.flex-basis-content {
    flex-basis: content !important;
}

.flex-wrap {
    flex-wrap: wrap !important;
}

.flex-nowrap {
    flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
}

.justify-content-start {
    justify-content: flex-start !important;
}

.justify-content-end {
    justify-content: flex-end !important;
}

.justify-content-center {
    justify-content: center !important;
}

.justify-content-between {
    justify-content: space-between !important;
}

.justify-content-around {
    justify-content: space-around !important;
}

.justify-self-start {
    justify-self: flex-start !important;
}

.justify-self-end {
    justify-self: flex-end !important;
}

.justify-self-center {
    justify-self: center !important;
}

.align-items-start {
    align-items: flex-start !important;
}

.align-items-end {
    align-items: flex-end !important;
}

.align-items-center {
    align-items: center !important;
}

.align-items-baseline {
    align-items: baseline !important;
}

.align-items-stretch {
    align-items: stretch !important;
}

.align-content-start {
    align-content: flex-start !important;
}

.align-content-end {
    align-content: flex-end !important;
}

.align-content-center {
    align-content: center !important;
}

.align-content-between {
    align-content: space-between !important;
}

.align-content-around {
    align-content: space-around !important;
}

.align-content-stretch {
    align-content: stretch !important;
}

.align-self-auto {
    align-self: auto !important;
}

.align-self-start {
    align-self: flex-start !important;
}

.align-self-end {
    align-self: flex-end !important;
}

.align-self-center {
    align-self: center !important;
}

.align-self-baseline {
    align-self: baseline !important;
}

.align-self-stretch {
    align-self: stretch !important;
}

.order-first {
    order: -1 !important;
}

.order-0 {
    order: 0 !important;
}

.order-1 {
    order: 1 !important;
}

.order-2 {
    order: 2 !important;
}

.order-3 {
    order: 3 !important;
}

.order-4 {
    order: 4 !important;
}

.order-5 {
    order: 5 !important;
}

.order-last {
    order: 6 !important;
}

.m-0 {
    margin: 0 !important;
}

.m-1 {
    margin: 0.25rem !important;
}

.m-2 {
    margin: 0.5rem !important;
}

.m-3 {
    margin: 1rem !important;
}

.m-4 {
    margin: 1.5rem !important;
}

.m-5 {
    margin: 3rem !important;
}

.m-auto {
    margin: auto !important;
}

.mx-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
}

.mx-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
}

.mx-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
}

.mx-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
}

.mx-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
}

.mx-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
}

.mx-auto {
    margin-right: auto !important;
    margin-left: auto !important;
}

.my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.my-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
}

.my-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
}

.my-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
}

.my-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
}

.my-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
}

.my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
}

.mt-0 {
    margin-top: 0 !important;
}

.mt-1 {
    margin-top: 0.25rem !important;
}

.mt-2 {
    margin-top: 0.5rem !important;
}

.mt-3 {
    margin-top: 1rem !important;
}

.mt-4 {
    margin-top: 1.5rem !important;
}

.mt-5 {
    margin-top: 3rem !important;
}

.mt-6 {
    margin-top: 3.5rem;
}

.mt-auto {
    margin-top: auto !important;
}

.mr-0 {
    margin-right: 0 !important;
}

.mr-1 {
    margin-right: 0.25rem !important;
}

.mr-2 {
    margin-right: 0.5rem !important;
}

.mr-3 {
    margin-right: 1rem !important;
}

.mr-4 {
    margin-right: 1.5rem !important;
}

.mr-5 {
    margin-right: 3rem !important;
}

.mr-auto {
    margin-right: auto !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.mb-1 {
    margin-bottom: 0.25rem !important;
}

.mb-2 {
    margin-bottom: 0.5rem !important;
}

.mb-3 {
    margin-bottom: 1rem !important;
}

.mb-4 {
    margin-bottom: 1.5rem !important;
}

.mb-5 {
    margin-bottom: 3rem !important;
}

.mb-auto {
    margin-bottom: auto !important;
}

.ml-0 {
    margin-left: 0 !important;
}

.ml-1 {
    margin-left: 0.25rem !important;
}

.ml-2 {
    margin-left: 0.5rem !important;
}

.ml-3 {
    margin-left: 1rem !important;
}

.ml-4 {
    margin-left: 1.5rem !important;
}

.ml-5 {
    margin-left: 3rem !important;
}

.ml-auto {
    margin-left: auto !important;
}

.p-0 {
    padding: 0 !important;
}

.p-1 {
    padding: 0.25rem !important;
}

.p-2 {
    padding: 0.5rem !important;
}

.p-3 {
    padding: 1rem !important;
}

.p-4 {
    padding: 1.5rem !important;
}

.p-5 {
    padding: 3rem !important;
}

.px-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
}

.px-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
}

.px-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
}

.px-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
}

.px-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
}

.px-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
}

.py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
}

.py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
}

.py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}

.py-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
}

.py-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
}

.pt-0 {
    padding-top: 0 !important;
}

.pt-1 {
    padding-top: 0.25rem !important;
}

.pt-2 {
    padding-top: 0.5rem !important;
}

.pt-3 {
    padding-top: 1rem !important;
}

.pt-4 {
    padding-top: 1.5rem !important;
}

.pt-5 {
    padding-top: 3rem !important;
}

.pr-0 {
    padding-right: 0 !important;
}

.pr-1 {
    padding-right: 0.25rem !important;
}

.pr-2 {
    padding-right: 0.5rem !important;
}

.pr-3 {
    padding-right: 1rem !important;
}

.pr-4 {
    padding-right: 1.5rem !important;
}

.pr-5 {
    padding-right: 3rem !important;
}

.pb-0 {
    padding-bottom: 0 !important;
}

.pb-1 {
    padding-bottom: 0.25rem !important;
}

.pb-2 {
    padding-bottom: 0.5rem !important;
}

.pb-3 {
    padding-bottom: 1rem !important;
}

.pb-4 {
    padding-bottom: 1.5rem !important;
}

.pb-5 {
    padding-bottom: 3rem !important;
}

.pl-0 {
    padding-left: 0 !important;
}

.pl-1 {
    padding-left: 0.25rem !important;
}

.pl-2 {
    padding-left: 0.5rem !important;
}

.pl-3 {
    padding-left: 1rem !important;
}

.pl-4 {
    padding-left: 1.5rem !important;
}

.pl-5 {
    padding-left: 3rem !important;
}

.pl-6 {
    padding-left: 6.5rem !important;
}

.font-weight-light {
    font-weight: 300 !important;
}

.font-weight-lighter {
    font-weight: lighter !important;
}

.font-weight-normal {
    font-weight: 400 !important;
}

.font-weight-semi-bold {
    font-weight: 600 !important;
}

.font-weight-bold {
    font-weight: 700 !important;
}

.font-weight-bolder {
    font-weight: bolder !important;
}

.text-lowercase {
    text-transform: lowercase !important;
}

.text-uppercase {
    text-transform: uppercase !important;
}

.text-capitalize {
    text-transform: capitalize !important;
}

.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}

.text-center {
    text-align: center !important;
}

.text-primary {
    color: var(--primary-color) !important;
}

.text-secondary {
    color: var(--secondary-color) !important;
}

.text-warning {
    color: var(--warning-color) !important;
}

.text-caution {
    color: var(--caution-color) !important;
}

.text-advisory {
    color: var(--advisory-color) !important;
}

.text-accent {
    color: var(--accent-color) !important;
}

.text-info {
    color: var(--info-color) !important;
}

.text-nominal {
    color: var(--nominal-color) !important;
}

.text-muted {
    color: var(--muted-color) !important;
}

.text-selected {
    color: var(--selected-color) !important;
}

.text-disabled {
    color: var(--disabled-color) !important;
}

.text-notification {
    color: var(--notification-color) !important;
}

.lh-1 {
    line-height: 1 !important;
}

.lh-sm {
    line-height: 1.25 !important;
}

.lh-base {
    line-height: 1.5 !important;
}

.lh-lg {
    line-height: 2 !important;
}

.bg-transparent {
    background-color: transparent !important;
}

.bg-primary {
    background-color: var(--primary-color) !important;
}

.bg-secondary {
    background-color: var(--secondary-color) !important;
}

.bg-warning {
    background-color: var(--warning-color) !important;
}

.bg-caution {
    background-color: var(--caution-color) !important;
}

.bg-advisory {
    background-color: var(--advisory-color) !important;
}

.bg-accent {
    background-color: var(--accent-color) !important;
}

.bg-info {
    background-color: var(--info-color) !important;
}

.bg-nominal {
    background-color: var(--nominal-color) !important;
}

.bg-muted {
    background-color: var(--muted-color) !important;
}

.bg-selected {
    background-color: var(--selected-color) !important;
}

.bg-disabled {
    background-color: var(--disabled-color) !important;
}

.bg-notification {
    background-color: var(--notification-color) !important;
}

.fill-primary {
    fill: var(--primary-color) !important;
}

.fill-secondary {
    fill: var(--secondary-color) !important;
}

.fill-warning {
    fill: var(--warning-color) !important;
}

.fill-caution {
    fill: var(--caution-color) !important;
}

.fill-advisory {
    fill: var(--advisory-color) !important;
}

.fill-accent {
    fill: var(--accent-color) !important;
}

.fill-info {
    fill: var(--info-color) !important;
}

.fill-nominal {
    fill: var(--nominal-color) !important;
}

.fill-muted {
    fill: var(--muted-color) !important;
}

.fill-selected {
    fill: var(--selected-color) !important;
}

.fill-disabled {
    fill: var(--disabled-color) !important;
}

.fill-notification {
    fill: var(--notification-color) !important;
}

.text-wrap {
    white-space: normal !important;
}

.text-pre-wrap {
    white-space: pre-wrap !important;
}

.text-nowrap {
    white-space: nowrap !important;
}

.text-decoration-none {
    text-decoration: none !important;
}

.text-decoration-underline {
    text-decoration: underline !important;
}

.text-decoration-line-through {
    text-decoration: line-through !important;
}

.font-italic {
    font-style: italic !important;
}

.font-normal {
    font-style: normal !important;
}

.text-break {
    word-wrap: break-word !important;
}

.font-monospace {
    font-family: var(--mono-font-family) !important;
}

.user-select-all {
    -webkit-user-select: all !important;
    -moz-user-select: all !important;
    -ms-user-select: all !important;
    user-select: all !important;
}

.user-select-auto {
    -webkit-user-select: auto !important;
    -moz-user-select: auto !important;
    -ms-user-select: auto !important;
    user-select: auto !important;
}

.user-select-none {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
}

.pe-none {
    pointer-events: none !important;
}

.pe-auto {
    pointer-events: auto !important;
}

.cursor-pointer {
    cursor: pointer !important;
}

.cursor-default {
    cursor: default !important;
}

.cursor-auto {
    cursor: auto !important;
}

.rounded-sm {
    border-radius: 0.2rem !important;
}

.rounded,
.rounded-m {
    border-radius: 0.375rem !important;
}

.rounded-lg {
    border-radius: 0.5rem !important;
}

.rounded-circle {
    border-radius: 50% !important;
}

.rounded-pill {
    border-radius: 50rem !important;
}

.rounded-0 {
    border-radius: 0 !important;
}

.rounded-top {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
}

.rounded-right {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
    border-bottom-left-radius: 0.25rem !important;
    border-top-left-radius: 0.25rem !important;
}

.visible {
    visibility: visible !important;
}

.invisible {
    visibility: hidden !important;
}

.opacity-50 {
    opacity: 0.5;
}


.v-gap-0 > *:not(:first-child):not(.exclude-item) {
    margin-top: 0.0rem;
}

.v-gap-1 > *:not(:first-child):not(.exclude-item) {
    margin-top: 0.5rem;
}

.v-gap-2 > *:not(:first-child):not(.exclude-item) {
    margin-top: 1.0rem;
}

.v-gap-3 > *:not(:first-child):not(.exclude-item) {
    margin-top: 1.5rem;
}

.v-gap-4 > *:not(:first-child):not(.exclude-item) {
    margin-top: 2.0rem;
}

.v-gap-5 > *:not(:first-child):not(.exclude-item) {
    margin-top: 2.5rem;
}

.v-gap-6 > *:not(:first-child):not(.exclude-item) {
    margin-top: 3.5rem;
}

.h-gap-0 > *:not(:first-child):not(.exclude-item) {
    margin-left: 0.0rem;
}

.h-gap-1 > *:not(:first-child):not(.exclude-item) {
    margin-left: 0.5rem;
}

.h-gap-2 > *:not(:first-child):not(.exclude-item) {
    margin-left: 1.0rem;
}

.h-gap-3 > *:not(:first-child):not(.exclude-item) {
    margin-left: 1.5rem;
}

.h-gap-4 > *:not(:first-child):not(.exclude-item) {
    margin-left: 2.0rem;
}

.h-gap-5 > *:not(:first-child):not(.exclude-item) {
    margin-left: 2.5rem;
}

.h-gap-6 > *:not(:first-child):not(.exclude-item) {
    margin-left: 5.0rem;
}

.no-hover:hover {
    border: initial;
}

.tooltip {
    height: 3.4rem;
    width: 3.4rem;
    margin-top: -2.2rem;
}

.tooltip .tooltip-text {
    visibility: hidden;
    background-color: var(--secondary-color);
    color: var(--info-color);
    border-radius: 5px;
    padding: 5px;
    position: relative;
    z-index: 100;
    opacity: 0.7;
    bottom: 12px;
}

.tooltip:hover .tooltip-text {
    visibility: visible;
}
